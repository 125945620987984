<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          Context Area
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          The main purpose of the context is to guide the AI and control its behavior for conversations.
        </div>
      </div>
      <div class="row text-center">
        <div class="col-sm-12 text-center">
          Send Context <input type="checkbox" class="form-control" v-model="send_context">
          <textarea class="form-control" rows="10" cols="10" v-model="context"></textarea>
        </div>
      </div>
    </div>
    <div class="chat-component">
        <div class="chat-display" ref="chatDisplay">
            <div v-for="message in messages" :key="message.id" :class="['message', message.type]">
                <span v-if="message.type === 'user'">You: </span>
                <span v-else-if="message.type === 'bot'">TCN AI: </span>
                <div v-else-if="message.type === 'loading'">
                    <b-spinner small></b-spinner> {{ message.text }}
                </div>
                <!-- Use v-html to render HTML content -->
                <span v-if="message.type !== 'loading'" v-html="message.text"></span>
            </div>
        </div>
        <input v-model="userInput" @keyup.enter="sendMessage" placeholder="Type a message..." />
    </div>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button type="primary" round @click="closeModal('error')">OK</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<l-button type="primary" round @click="closeModal('success')">OK</l-button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Dialog } from 'element-ui';
import axios from "axios";

export default {
  name: "CoachChatComponent",
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      randomUUID: '',
      isSending: false,
      send_context: true,
      userInput: "",
      error_txt: null,
      success_txt: null,
      spinning: false,
      context: "Role: You are TCN-AI, a friendly and knowledgeable expert in health and medical cannabis. You provide brief, clear answers, and when further clarification is needed, you ask thoughtful questions to avoid assumptions. Your responses are pro-cannabis, aiming to guide and support the user in an approachable and helpful way.\n",
      messages: [],
      modals: {
        error: false,
        success: false,
      },
    };
  },
  mounted() {
    this.randomUUID = this.makeid(12);
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const chatDisplay = this.$refs.chatDisplay;
        chatDisplay.scrollTop = chatDisplay.scrollHeight;
      });
    },
    async sendMessage() {
      if (this.isSending) {
        return;
      }
      this.isSending = true;

      this.scrollToBottom();
      if (!this.userInput.trim()) {
        return;
      }
      this.spinning = true;

      const userMessage = {
        id: this.messages.length,
        text: this.userInput,
        type: "user",
      };
      if (this.send_context) {
        userMessage.context = this.context;
      }
      this.messages.push(userMessage);
      this.scrollToBottom();

      const theMessage = {
        "action": "query",
        "conversation_id": this.randomUUID,
        "query": this.userInput,
        "key": this.randomUUID,
        "user_key": 'tcn_key1',
        "api_key": 'tcn_api_key1',
      };
      if (this.send_context) {
        theMessage.context = this.context;
      }
      this.userInput = "";

      const loadingMessage = {
        id: this.messages.length,
        text: "Waiting for response, this may take up to two minutes...",
        type: "loading",
      };
      if (this.send_context) {
        loadingMessage.context = this.context;
      }
      this.messages.push(loadingMessage);

      try {
        const response = await axios.post("https://tcn-api.aivector.com/search", theMessage, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: 500 * 1000 // Setting timeout to 500 seconds
        });
        this.send_context = false;
        const botMessage = {
          id: this.messages.length,
          text: response.data.response,
          type: "bot",
        };
        // Remove loading message once the response is received
        this.messages.pop();

        this.messages.push(botMessage);
        this.scrollToBottom();
        this.isSending = false;
      } catch (error) {
        this.spinning = false;
        this.isSending = false;
        if (error.code === 'ECONNABORTED') {
          this.handleError('Connection was aborted!')
        } else {
          this.handleError('There was an error sending the message! ' + error)
        }
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    handleSuccess(success_msg) {
      const self = this;
      this.success_txt = success_msg;
      this.openModal('error');
      this.spinning = false;
      setTimeout(function () {
        self.modals['error'] = false; // Use your variable name
      }, 1500); // Hide after 5 secs
    },
    handleError(error_msg) {
      const self = this;
      this.error_txt = error_msg;
      this.openModal('error');
      this.spinning = false;
      setTimeout(function () {
        self.modals['error'] = false; // Use your variable name
      }, 1500); // Hide after 5 secs
    },
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    }
  },

}
</script>

<style scoped>
.chat-component {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.chat-display {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  overflow-y: auto;
  max-height: 700px;
}

input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.message {
  padding: 10px;
  margin: 5px 0;
  border-bottom: 1px solid #eee; /* Lightly marked border between messages */
}

.user {
  background-color: #fff; /* White background for user message */
}

.bot {
  background-color: #f0f0f0; /* Light grey background for bot message */
}
</style>
